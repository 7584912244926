<template>
  <v-dialog max-width="500" persistent v-model="mostrar">
    <v-card :loading="loading">
      <v-toolbar dense dark flat color="primary">
        <v-toolbar-title>Nueva aplicación de examen</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn fab icon @click="$emit('cancelar')">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-4">
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="nombre"
              label="Nombre"
              outlined
              dense
              :error-messages="errors.nombre"
              @input="validarNombre()"
              @blur="validarNombre()"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="criterio"
              :items="criteriosDisponibles"
              item-value="_id"
              item-text="nombre"
              label="Criterio de evaluación"
              outlined
              dense
              :loading="loadingCriterios"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                  <v-list-item-subtitle v-if="item.seccion">{{
                    item.seccion
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </template></v-select
            >
          </v-col>
          <v-col cols="12">
            <div class="d-flex" style="width: 100%">
              <v-spacer></v-spacer>
              <v-btn
                color="secondary"
                outlined
                small
                class="mr-2"
                @click="$emit('cancelar')"
                >Cancelar</v-btn
              >
              <v-btn
                color="primary"
                small
                :disabled="!formularioEsValido"
                :loading="loading"
                @click="subirAplicacion()"
                >Guardar</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import { obtenerEvaluacionCursoService } from "../../encuadre/evaluacion/evaluacion.service";
import { crearAplicacion } from "./examenAplicaciones.service";

export default {
  mixins: [validationMixin],

  props: {
    mostrar: { type: Boolean, default: false },
    tipoCurso: { type: String, required: true },
    idCurso: { type: String, required: true },
  },

  data: () => ({
    loading: false,
    loadingCriterios: false,
    nombre: "",
    criterios: [],
    criterio: null,
    errors: {
      nombre: "",
    },
  }),

  validations: {
    nombre: { required },
  },

  computed: {
    criteriosDisponibles() {
      return [
        {
          nombre: "Ninguno",
          _id: null,
        },
        ...this.criterios,
      ];
    },

    idModulo() {
      return this.$route.params.idModulo;
    },

    formularioEsValido() {
      if (this.errors.nombre.length) return false;
      return true;
    },
  },

  mounted() {
    this.cargarEvaluacion();
  },

  methods: {
    async cargarEvaluacion() {
      try {
        this.criterios = [{ _id: null, nombre: "Ninguno", seccion: "" }];
        this.loadingCriterios = true;
        const serverResponse = await obtenerEvaluacionCursoService(
          this.tipoCurso,
          this.idCurso
        );
        this.loadingCriterios = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          serverResponse.secciones.forEach((seccion) => {
            const criteriosValidos = seccion.criterios.filter(
              (x) => !x.asistencias.requerido
            );
            const criterios = criteriosValidos.map((criterio) => {
              return {
                _id: criterio._id,
                nombre: criterio.nombre,
                seccion: seccion.nombre,
              };
            });
            this.criterios.push(...criterios);
          });
        }
      } catch (error) {
        this.loadingCriterios = false;
        this.$appErrorMessage();
      }
    },

    validarNombre() {
      this.$v.nombre.$touch();
      this.errors.nombre = [
        ...(this.$v.nombre.required
          ? []
          : ["El nombre de la aplicación es requerido."]),
      ];
    },

    async subirAplicacion() {
      this.validarNombre();
      if (!this.formularioEsValido) return;

      this.loading = true;

      try {
        const requestData = {
          nombre: this.nombre,
          criterio: this.criterio,
        };

        const serverResponse = await crearAplicacion({
          idCurso: this.idCurso,
          idModulo: this.idModulo,
          data: requestData,
        });
        this.loading = false;

        this.$emit("aplicacionGuardada", serverResponse.aplicacion);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
